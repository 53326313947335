// extracted by mini-css-extract-plugin
export var coliPad = "page-module--coliPad--74886";
export var coliPadHalf = "page-module--coliPadHalf--db976";
export var fullLink = "page-module--fullLink--5eb10";
export var markdownCard = "page-module--markdownCard--c1caf";
export var markdownContent = "page-module--markdownContent--e092b";
export var markdownIcon = "page-module--markdownIcon--7164e";
export var pageParentRow = "page-module--pageParentRow--bab13";
export var pageParentRowBusiness = "page-module--pageParentRowBusiness--a6aac";
export var pageParentRowMedium = "page-module--pageParentRowMedium--1fed5";
export var pageRow = "page-module--pageRow--fee96";