import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Reports from "../assets/Reliability/icon-reporting.svg";
import Data from "../assets/Reliability/icon-access-quality-data.svg";
import Outcomes from "../assets/Reliability/icon-outcomes.svg";
import Mx from "../assets/Reliability/reliability-mx.jpg";
import Pirep from "../assets/Reliability/reliability-pirep.jpg";
import Airframe from "../assets/Reliability/reliability-airframe.jpg";
import Performance from "../assets/Reliability/reliability-performance.jpg";
import Records from "../assets/Tech-Log/icon-time-savings.svg";
import Investment from "../assets/Tech-Log/icon-reduced-cost.svg";
import FleetOverview from "../assets/CAMO/highly-experienced.svg";
import Tick from "../assets/icon-tick.svg";
import Seo from "../components/SEO";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import Started from "../components/Layout/started";
import * as Scroll from "react-scroll";

const { scroller } = Scroll;

const Background = styled.div`
  width: 100vw;
  background-image: linear-gradient(to top, #f8fcff, var(--white));
  padding-bottom: 80px;
`;

const QualityWrapper = styled.div`
  padding: 100px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  color: #242d41;
  & h2 {
    grid-column: 1/4;
  }
  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
    padding: 40px 0;
    & h2 {
      grid-column: auto;
      font-size: 28px;
    }
  }
`;

const ConvoWrapper = styled.div`
  grid-row: 1/4;
  grid-column: 4/5;
  @media screen and (max-width: 820px) {
    margin-top: 90px;
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
`;

const QualityText = styled.p`
  grid-row: 2/3;
  grid-column: 1/4;
  font-size: 18px;
  line-height: 1.56;
  color: rgba(36, 45, 65, 0.9);
  padding-right: 140px;
  padding-bottom: 40px;
  @media screen and (max-width: 991px) {
    padding-right: 70px;
  }
  @media screen and (max-width: 576px) {
    grid-row: auto;
    grid-column: auto;
    padding-right: 0;
    font-size: 16px;
  }
`;

const SectorDiv = styled.div`
  padding-right: 24px;
  @media screen and (max-width: 576px) {
    text-align: center;
  }
`;

const SectorTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  margin-top: 24px;
`;

const SectorText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: rgba(36, 45, 65, 0.8);
`;

const ConvoHeader = styled.h5`
  font-weight: 600;
  margin-top: 24px;
`;

const LineBreak = styled.div`
  height: 1px;
  background-color: #eeeff3;
  margin-top: 28px;
  margin-bottom: 28px;
`;

const Contact = styled.div`
  margin: 28px 0 0;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0 6px 20px 0 rgba(36, 45, 65, 0.1);
  background-color: #ffffff;
`;

const Email = styled.h6`
  line-height: 1.5;
  color: #126fd6;
`;

const TickDiv = styled.div`
  display: flex;
  margin-bottom: 12px;
`;
const TickImg = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 16px;
`;

const TickText = styled.span`
  color: rgba(36, 45, 65, 0.7);
  @media screen and (max-width: 451px) {
    text-align: left;
  }
`;

const SectionDiv = styled.div`
  margin: 150px 0;
  display: flex;
  color: rgba(36, 45, 65, 0.9);
  @media screen and (max-width: 991px) {
    margin: 60px 0;
    flex-direction: column;
    & h2 {
      font-size: 28px;
    }
  }
  @media screen and (max-width: 576px) {
    margin: 40px 0;
    flex-direction: column;
    & h2 {
      font-size: 22px;
    }
  }
`;

const ReliabilityText = styled.p`
  font-size: 18px;
  line-height: 1.56;
  padding-bottom: 20px;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}` : "unset")};
  text-align: ${({ centerText }) => (centerText ? "center" : "unset")};
  @media screen and (max-width: 576px) {
    font-size: 16px;
  }
`;

const SectionImage = styled.img`
  max-height: 320px;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0 6px 20px 0 rgba(36, 45, 65, 0.1);
  @media screen and (max-width: 991px) {
    max-height: fit-content;
  }
  /* @media screen and (max-width: 820px) {
    display: none;
  } */
`;

const ImageWrapper = styled.div`
  flex: 8;
  @media screen and (max-width: 821px) {
    margin-top: 20px;
  }
  @media screen and (max-width: 576px) {
    flex: 1;
  }
`;

const ImageWrapperShowBig = styled.div`
  flex: 8;
  @media screen and (max-width: 821px) {
    display: none;
  }
`;

const ImageWrapperShowSmall = styled.div`
  display: none;
  @media screen and (max-width: 821px) {
    margin-top: 20px;
    display: block;
  }
  @media screen and (max-width: 576px) {
    flex: 1;
  }
`;

const TextWrapper = styled.div`
  flex: 10;
  padding-right: 20px;
  @media screen and (max-width: 991px) {
    flex: 1;
    margin-top: 60px;
  }
  @media screen and (max-width: 576px) {
    flex: 1;
    margin-top: 40px;
  }
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageCard = styled.img`
  height: 140px;
  width: 140px;
`;

const CardHeading = styled.p`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.02px;
  color: rgb(30, 91, 181);
  margin-top: 16px;
`;

const CardText = styled.p`
  font-size: 16px;
  color: #242d41;
  letter-spacing: 0.02px;
  display: inline-block;
`;

const WhatCanBeHeader = styled.h3`
  padding-bottom: 20px;
`;

const Reliability = (props) => {
  const sectors = [
    {
      img: Reports,
      title: "Generate real-time reports",
      text: "Automate generation of reliability reports, on a real-time basis",
    },
    {
      img: Data,
      title: "Access quality data",
      text: "Import data from multiple sources including M&E System, Electronic Tech Log, Scheduling System & third party vendors",
    },
    {
      img: Outcomes,
      title: "Focus on value and outcomes",
      text: "Focus on actionable outcomes as opposed to regulatory reporting requirements",
    },
  ];

  return (
    <Layout>
      <Seo />
      <PageHeader
        header="Reliability Analytics"
        subHeader="Use data to increase aircraft availability, safety and reduce cost"
        mediumHeader
        longTag
        link="#schedule_demo_form"
        linkText="Schedule a demo"
        handleLinkScroll={(e) => {
          e.preventDefault();
          scroller.scrollTo("schedule_demo_form", {
            duration: 800,
            smooth: true,
            offset: -60,
          });
        }}
      />

      <Background>
        <Container>
          <QualityWrapper>
            <h2>Drive impactful decisions with real-time fleet data</h2>
            <QualityText>
              At TrustFlight we understand the challenges of conventional
              reliability reporting. Every minute spent on manual data gathering
              and cleansing is a minute lost from fixing problems. With our
              real-time Reliability Analytics tool, the time and attention of
              your reliability team is focused on technical investigations and
              diagnosing issues rather than report data collection, leading to a
              significant improvement in operational reliability.
            </QualityText>

            {sectors.map((sector) => (
              <SectorDiv key={sector.title}>
                <img src={sector.img} alt={"tech-logo"}></img>
                <SectorTitle>{sector.title}</SectorTitle>
                <SectorText>{sector.text}</SectorText>
              </SectorDiv>
            ))}
            <ConvoWrapper>
              <ConvoHeader>Start the conversation</ConvoHeader>
              <LineBreak />
              <Contact>
                <p>
                  If you require more information or need any specialist advice
                  then get in touch with us
                </p>
                <Email>
                  <a href={"mailto:info@trustflight.com"}>
                    info@trustflight.com
                  </a>
                </Email>
              </Contact>
            </ConvoWrapper>
          </QualityWrapper>
        </Container>
      </Background>
      <Background>
        <Container>
          <SectionDiv>
            <ImageWrapperShowBig>
              <SectionImage src={Pirep} />
            </ImageWrapperShowBig>
            <TextWrapper>
              <h2>Real-time PIREP & MAREP analysis</h2>
              <ReliabilityText>
                Identify repeat discrepancies earlier and better understand
                factors causing unscheduled faults.
              </ReliabilityText>
              <div>
                <TickDiv>
                  <TickImg src={Tick} />
                  <TickText>Alert-level based reporting</TickText>
                </TickDiv>
                <TickDiv>
                  <TickImg src={Tick} />
                  <TickText>Easily identify trends</TickText>
                </TickDiv>
                <TickDiv>
                  <TickImg src={Tick} />
                  <TickText>Drill down to investigate specific faults</TickText>
                </TickDiv>
              </div>
            </TextWrapper>
            <ImageWrapperShowSmall>
              <SectionImage src={Pirep} />
            </ImageWrapperShowSmall>
          </SectionDiv>
          <SectionDiv>
            <ImageWrapperShowBig>
              <SectionImage src={Airframe} />
            </ImageWrapperShowBig>
            <TextWrapper>
              <h2>Airframe & component reliability</h2>
              <ReliabilityText>
                Component analytics allows easy performance monitoring of
                components to enable improvements in planning and cost
                reduction.
              </ReliabilityText>
              <div>
                <TickDiv>
                  <TickImg src={Tick} />
                  <TickText>Analyze component reliability</TickText>
                </TickDiv>
                <TickDiv>
                  <TickImg src={Tick} />
                  <TickText>Optimize inventory levels</TickText>
                </TickDiv>
                <TickDiv>
                  <TickImg src={Tick} />
                  <TickText>Benchmark performance</TickText>
                </TickDiv>
              </div>
            </TextWrapper>
            <ImageWrapperShowSmall>
              <SectionImage src={Airframe} />
            </ImageWrapperShowSmall>
          </SectionDiv>
          <SectionDiv>
            <TextWrapper>
              <h2>Performance benchmarking</h2>
              <ReliabilityText>
                Performance benchmarking helps in understanding how well an
                operation is performing as well as identifying areas to focus on
                for improvement.
              </ReliabilityText>
              <div>
                <TickDiv>
                  <TickImg src={Tick} />
                  <TickText>Easily view internal KPIs</TickText>
                </TickDiv>
                <TickDiv>
                  <TickImg src={Tick} />
                  <TickText>Benchmark performance against industry</TickText>
                </TickDiv>
                <TickDiv>
                  <TickImg src={Tick} />
                  <TickText>Understand department performance</TickText>
                </TickDiv>
              </div>
            </TextWrapper>
            <ImageWrapper>
              <SectionImage src={Performance} />
            </ImageWrapper>
          </SectionDiv>
          <SectionDiv>
            <TextWrapper>
              <h2>Maintenance programme analysis</h2>
              <ReliabilityText>
                Proactive maintenance programme review and changes can provide
                significant cost savings and reliability enhancement.
              </ReliabilityText>
              <div>
                <TickDiv>
                  <TickImg src={Tick} />
                  <TickText>View AMP effectiveness</TickText>
                </TickDiv>
                <TickDiv>
                  <TickImg src={Tick} />
                  <TickText>Inform task escalation/de-escalations</TickText>
                </TickDiv>
                <TickDiv>
                  <TickImg src={Tick} />
                  <TickText>Optimize interval usage</TickText>
                </TickDiv>
              </div>
            </TextWrapper>
            <ImageWrapper>
              <SectionImage src={Mx} />
            </ImageWrapper>
          </SectionDiv>
          <Row>
            <Col md={12}>
              <Row>
                <Col>
                  <CenteredDiv>
                    <WhatCanBeHeader>What can be achieved?</WhatCanBeHeader>
                  </CenteredDiv>
                  <CenteredDiv>
                    <ReliabilityText maxWidth="80%" centerText>
                      Our Reliability Analytics tool delivers improved
                      efficiency, accuracy, visibility and data management,
                      ultimately leading to better decision making and increased
                      safety and efficiency of your operations.
                    </ReliabilityText>
                  </CenteredDiv>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <CardWrapper>
                    <ImageCard src={FleetOverview} alt="fleet overview" />
                    <CardHeading>Increase in availability</CardHeading>
                    <CardText>
                      20% reduction in unscheduled aircraft downtime
                    </CardText>
                  </CardWrapper>
                </Col>
                <Col sm={4}>
                  <CardWrapper>
                    <ImageCard src={Records} alt="validated records" />
                    <CardHeading>Time savings</CardHeading>
                    <CardText>
                      Save upwards of 20hrs per month per aircraft in time spent
                      generating reports
                    </CardText>
                  </CardWrapper>
                </Col>
                <Col sm={4}>
                  <CardWrapper>
                    <ImageCard src={Investment} alt="return on investment" />
                    <CardHeading>Cost savings</CardHeading>
                    <CardText>Reduce operating costs by up to 10%</CardText>
                  </CardWrapper>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Background>
      <a id="schedule_demo_form" aria-label="Schedule a demo form" />
      <Started
        title="Let's start the conversation"
        text="Talk to our services team to find out how you can benefit from our expertise."
        buttonText="Ready to become data-driven?"
        hubSpotFormId="21c7d635-311c-4a61-9608-ca0644aef479"
      />
    </Layout>
  );
};
export default Reliability;
