import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const HubSpotFormDiv = styled.div`
  padding-bottom: 50px;
`;

const HubSpotForm = ({ formId }) => {
  const handleOnLoad = () => {
    if (window?.hbspt) {
      window.hbspt.forms.create({
        portalId: "44440682",
        formId: formId,
        target: `#hubspotForm-${formId}`,
      });
    }
  };
  const handleScriptInject = ({ scriptTags }) => {
    if (scriptTags?.length > 0) {
      // On refresh we need to wait until the script has loaded
      const scriptTag = scriptTags[0];
      scriptTag.addEventListener("load", handleOnLoad);
    } else {
      // On page change script should already exist in the state
      handleOnLoad();
    }
  };

  return (
    <>
      <HubSpotFormDiv id={`hubspotForm-${formId}`}>
        <Helmet
          script={[{ src: "https://js.hsforms.net/forms/v2.js" }]}
          onChangeClientState={(newState, addedTags) =>
            handleScriptInject(addedTags)
          }
        ></Helmet>
        {/* HubSpot form will be rendered here */}
      </HubSpotFormDiv>
    </>
  );
};

export default HubSpotForm;
