import React from "react";
import styled from "styled-components";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Container, Button, Row, Col } from "react-bootstrap";
import GoArrow from "../../assets/go-arrow-blue.svg";
import * as pageStyles from "../../pages/page.module.less";
import * as styles from "./started.module.less";
import HubSpotForm from "../Hubspot/HubspotForm";

const StartedWrapper = styled.div`
  font-family: "Open Sans";
  text-align: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 100px;
  align-items: center;
  @media screen and (max-width: 576px) {
    padding-bottom: 60px;
  }
`;

const StartedHeader = styled.h3`
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.04px;
  color: #242d41;
  padding-bottom: 20px;
  @media screen and (max-width: 1200px) {
    font-size: 24px;
  }
  @media screen and (max-width: 576px) {
    font-size: 22px;
  }
`;

const StartedText = styled.p`
  font-size: 18px;
  letter-spacing: 0.03px;
  color: #242d41;
  padding-bottom: 14px;
  font-weight: 300;
  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
`;

const Paperless = styled.span`
  font-size: 16px;
  letter-spacing: 0.02px;
  color: #242d41;
  padding: 0 10px;
  @media screen and (max-width: 576px) {
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
  }
`;

const Touch = styled.span`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.02px;
  color: #1e5bb5;
  padding: 0 8px;
  @media screen and (max-width: 576px) {
    font-size: 14px;
  }
`;

const StyledArrow = styled.img`
  height: 14px;
`;

const Started = (props) => {
  return (
    <Container>
      <Row className={`justify-content-md-center ${pageStyles.pageParentRow}`}>
        <Col>
          <StartedWrapper>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInDown"
              offset={0}
            >
              <StartedHeader>
                {props.title || "Let's get started"}
              </StartedHeader>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInUp"
              offset={0}
            >
              <StartedText>
                {props.text ||
                  "Talk to our sales team to find out how you can transform your operation with our products and services."}
              </StartedText>
            </AnimationOnScroll>
            {!props.hubSpotFormId && (
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInUp"
                offset={0}
                delay={150}
              >
                <Button
                  href={props.link || "/contact/"}
                  variant="link"
                  className={styles.ctaWhite}
                >
                  <Paperless>
                    {props.buttonText || "Ready to go paperless?"}
                  </Paperless>
                  <Touch>{props.buttonTextStrong || "Get in touch"}</Touch>
                  <StyledArrow
                    src={GoArrow}
                    alt="arrow"
                    className={styles.goArrow}
                  />
                </Button>
              </AnimationOnScroll>
            )}
          </StartedWrapper>
        </Col>
      </Row>
      {props.hubSpotFormId && <HubSpotForm formId={props.hubSpotFormId} />}
    </Container>
  );
};

export default Started;
